
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "ContentComponent",
  computed: {
    ...mapState({
      data: (state: unknown) => (state as State).kazanExpress.data,
      maxRequestSize: (state: unknown) => (state as State).kazanExpress.maxRequestSize,
    }),

    ...mapGetters({
      isLoading: "kazanExpress/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      fetchShops: "kazanExpress/fetchShops",
      fetchTotalAmtOrders: "kazanExpress/fetchTotalAmtOrders",
      fetchOrders: "kazanExpress/fetchOrders",
      fetchProducts: "kazanExpress/fetchProducts",
    }),
    ...mapMutations({
      filterData: "kazanExpress/filterData",
    }),

    showError(summary: string, detail: string): void {
      this.$toast.add({ severity: "error", summary, detail, life: 5000 });
    },
  },
  async mounted() {
    if (this.data.isAuth && !this.isLoading) {
      this.data.orders = [];
      this.data.products = [];
      await this.fetchShops().catch((err) =>
        this.showError("Ошибка загрузки информации о магазинах", err.response.data.error)
      );
      await this.fetchTotalAmtOrders().catch((err) =>
        this.showError("Ошибка загрузки количества продаж", err.response.data.error)
      );
      for (let page = 0; page < Math.ceil(this.data.totalAmtOrders / this.maxRequestSize); page++) {
        await this.fetchOrders(page).catch((err) =>
          this.showError("Ошибка загрузки списка продаж", err.response.data.error)
        );
      }

      for (const shop of this.data.shops) {
        await this.fetchProducts(shop.id).catch((err) =>
          this.showError("Ошибка загрузки списка товаров", err.response.data.error)
        );
      }

      this.data.ordersAll = JSON.parse(JSON.stringify(this.data.orders));
      this.data.productsAll = JSON.parse(JSON.stringify(this.data.products));

      // Need to optimize
      this.data.ordersAll.forEach((order) => {
        this.data.productsAll.find((product) => order.skuTitle == product.sku)?.ordersList.push(order);
      });

      if (this.data.ordersAll.length) {
        this.data.minDate = new Date(this.data.ordersAll[this.data.ordersAll.length - 1].date);
        this.data.maxDate = new Date(this.data.ordersAll[0].date);
        this.data.date = [this.data.minDate, this.data.maxDate];
      }
      this.filterData();
    }
  },
});
