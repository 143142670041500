import InfoCardComponent from "@/components/InfoCardComponent.vue";
import LoginComponent from "@/components/LoginComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import ContentComponent from "@/components/ContentComponent.vue";
import DashboardComponent from "@/components/DashboardComponent.vue";
import DashboardHeaderComponent from "@/components/DashboardHeaderComponent.vue";
import PaymentComponent from "@/components/PaymentComponent.vue";

export default [
  InfoCardComponent,
  LoginComponent,
  HeaderComponent,
  ContentComponent,
  FooterComponent,
  DashboardComponent,
  DashboardHeaderComponent,
  PaymentComponent,
];
