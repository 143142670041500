
import { Auth, Registr } from "@/interfaces/auth.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "LoginComponent",
  data() {
    return {
      authData: { username: "", password: "" } as Auth,
      regData: { username: "", password: "", name: "" } as Registr,
      isLogin: true,
      isSubmitedLogin: false,
      isSubmitedReg: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state: unknown) => (state as State).paymentServer.loading,
    }),
  },
  methods: {
    ...mapActions({
      authPayment: "paymentServer/auth",
      regPayment: "paymentServer/registration",
    }),

    login(): void {
      this.isSubmitedLogin = true;
      this.authPayment(this.authData).catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка входа",
          detail: "Неверный логин или пароль",
          life: 3000,
        });
      });
    },

    registration(): void {
      this.isSubmitedReg = true;
      this.regPayment(this.regData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Выполнено",
            detail: "Регистрация выполнена успешно",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ошибка регистрации",
            detail: "Попробуйте изменить логин",
            life: 3000,
          });
        });
    },

    isSubmitDisabled(isReg = false): boolean {
      return !this.isEmailValid(isReg) || !this.isPasswordValid(isReg) || !this.isNameValid(isReg);
    },

    isNameValid(isReg = false): boolean {
      if (isReg) {
        return this.regData.name != "";
      }
      return true;
    },

    isEmailValid(isReg = false): boolean {
      if (isReg) {
        return this.regData.username != "";
      }
      return this.authData.username != "";
    },

    isPasswordValid(isReg = false): boolean {
      if (isReg) {
        return this.regData.password != "";
      }
      return this.authData.password != "";
    },

    linkClick(): void {
      this.authData = { username: "", password: "" };
      this.regData = { username: "", password: "", name: "" };
      this.isSubmitedLogin = false;
      this.isSubmitedReg = false;
      this.isLogin = !this.isLogin;
    },
  },
});
