import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79604430"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-clock",
  style: {"font-size":"1.2rem","color":"var(--blue-500)"},
  title: 'Обрабатывается'
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-times-circle",
  style: {"font-size":"1.2rem","color":"var(--red-500)"},
  title: 'Отменено'
}
const _hoisted_4 = {
  key: 2,
  class: "pi pi-check-circle",
  style: {"font-size":"1.2rem","color":"var(--green-500)"},
  title: 'Доступно к выводу'
}
const _hoisted_5 = { class: "clip" }
const _hoisted_6 = {
  key: 0,
  class: "clip"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.data.orders.filter((order) => order.productTitle.toLowerCase().includes(_ctx.data.searchQuery.toLowerCase())),
      class: "p-datatable-sm",
      style: {"font-size":"small"},
      scrollable: true,
      scrollHeight: "flex",
      virtualScrollerOptions: { itemSize: 38 }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "status",
          style: {"max-width":"40px","min-width":"40px"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", null, [
              (data.status === 'PROCESSING')
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true),
              (data.status === 'CANCELED')
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : _createCommentVNode("", true),
              (data.status === 'TO_WITHDRAW')
                ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "date",
          header: "Дата создания",
          style: {"min-width":"150px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(new Date(data.date).toLocaleString()), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "dateIssued",
          header: "Дата получения",
          style: {"min-width":"150px","justify-content":"center"}
        }, {
          body: _withCtx(({ data }) => [
            (data.dateIssued)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(new Date(data.dateIssued).toLocaleString()), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, " - "))
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "orderId",
          header: "№ заказа",
          style: {"min-width":"100px"}
        }),
        _createVNode(_component_Column, {
          field: "skuTitle",
          header: "SKU",
          style: {"min-width":"200px"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              class: "clip",
              title: data.skuTitle
            }, _toDisplayString(data.skuTitle), 9, _hoisted_8)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "productTitle",
          header: "Наименование",
          style: {"min-width":"200px"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              class: "clip",
              title: data.productTitle
            }, _toDisplayString(data.productTitle), 9, _hoisted_9)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "amount",
          header: "Количество",
          style: {"min-width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.amount) + "шт.", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "sellPrice",
          header: "Цена",
          style: {"min-width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.sellPrice) + "₽", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "purchasePrice",
          header: "Себестоимость",
          style: {"min-width":"130px"}
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.purchasePrice) + "₽", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "sellerProfit",
          header: "К выводу",
          style: {"min-width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.sellerProfit) + "₽", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "withdrawnProfit",
          header: "Выведено",
          style: {"min-width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.withdrawnProfit) + "₽", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "commission",
          header: "Комиссия"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.commission) + "₽", 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}