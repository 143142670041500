import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78764202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "test" }
const _hoisted_5 = {
  key: 0,
  class: "a"
}
const _hoisted_6 = {
  key: 1,
  class: "b"
}
const _hoisted_7 = {
  key: 2,
  class: "c"
}
const _hoisted_8 = { class: "links" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.data.products.filter((product) => product.name.toLowerCase().includes(_ctx.data.searchQuery.toLowerCase())),
      class: "p-datatable-sm",
      style: {"font-size":"small"},
      scrollable: true,
      scrollHeight: "flex",
      virtualScrollerOptions: { itemSize: 32 },
      sortField: "name",
      rowClass: _ctx.rowClass,
      sortOrder: 1
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Наименование",
          style: {"min-width":"200px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              class: "clip",
              title: data.name
            }, _toDisplayString(data.name), 9, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "sku",
          header: "SKU",
          style: {"min-width":"200px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              class: "clip",
              title: data.sku
            }, _toDisplayString(data.sku), 9, _hoisted_3)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.amount",
          header: "Продано",
          style: {"min-width":"100px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.ordersDetails.amount) + "шт. ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "quantityActive",
          header: "Остаток",
          style: {"min-width":"100px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.quantityActive) + "шт. ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.sellPrice",
          header: "Цена за шт.",
          style: {"min-width":"120px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.sellPrice.toFixed(2)) + "₽ ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.purchasePrice",
          header: "СС за шт.",
          style: {"min-width":"120px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.purchasePrice.toFixed(2)) + "₽ ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.commission",
          header: "Комиссия за шт.",
          style: {"min-width":"120px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.commission.toFixed(2)) + "₽ ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.profit",
          header: "Прибыль за шт.",
          style: {"min-width":"120px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.profit.toFixed(2)) + "₽ ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.profitAll",
          header: "Общая прибыль",
          style: {"min-width":"120px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.profitAll.toFixed(2)) + "₽ ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.ros",
          header: "Рентабельность",
          style: {"min-width":"150px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.ros.toFixed(2)) + "% ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.shareSum",
          header: "ABC по выручке",
          sortable: true,
          style: {"min-width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_4, [
              (data.ordersDetails.shareSum <= 80)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "A"))
                : (data.ordersDetails.shareSum <= 95)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "B"))
                  : (data.ordersDetails.shareSum > 95)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "C"))
                    : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "ordersDetails.refundsPercent",
          header: "Возвраты",
          style: {"min-width":"100px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(+data.ordersDetails.refunds) + "шт. (" + _toDisplayString(+data.ordersDetails.refundsPercent.toFixed(2)) + "%) ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "quantityMissing",
          header: "Потери",
          style: {"min-width":"100px"},
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data.quantityMissing) + "шт. ", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { style: {"min-width":"60px"} }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("a", {
                class: "pi pi-external-link",
                style: {"font-size":"1.2rem","color":"var(--blue-500)"},
                title: 'Ссылка на товар в ЛК',
                href: `https://business.kazanexpress.ru/seller/${data.shopId}/products/all/id/${data.productId}/main`,
                target: "_blank"
              }, null, 8, _hoisted_9),
              _createElementVNode("a", {
                class: "pi pi-external-link",
                style: {"font-size":"1.2rem","color":"var(--blue-500)"},
                title: 'Ссылка на товар в клиентской версии',
                href: `https://kazanexpress.ru/product/${data.productId}`,
                target: "_blank"
              }, null, 8, _hoisted_10)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "rowClass"])
  ]))
}