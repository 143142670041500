
import { PayRequest } from "@/interfaces/pay.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "PaymentComponent",
  computed: {
    ...mapState({
      paymentServerData: (state: unknown) => (state as State).paymentServer.data,
      paymentServerLoading: (state: unknown) => (state as State).paymentServer.loading,
    }),
  },
  methods: {
    ...mapActions({
      getSubscriptions: "paymentServer/getSubscriptions",
      getTariffs: "paymentServer/getTariffs",
      makePay: "paymentServer/pay",
    }),
    selectTariff(tariffId: number): void {
      const params: PayRequest = { tariffId, returnUrl: location.href };
      this.makePay(params).then(() => {
        const payUrl = this.paymentServerData.payResponse.confirmation.confirmation_url;
        location.replace(payUrl);
      });
    },
  },
  mounted() {
    this.getTariffs();
  },
});
