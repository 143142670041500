
import { Product } from "@/interfaces/products.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "AnalyticsTable",
  computed: {
    ...mapState({
      data: (state: unknown) => (state as State).kazanExpress.data,
      loading: (state: unknown) => (state as State).kazanExpress.loading,
    }),
  },
  methods: {
    rowClass(data: Product) {
      return data.quantityActive <= this.data.activeOrders ? "row-active" : undefined;
    },
  },
});
