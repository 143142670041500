import { OrderStatus } from "@/enums/order-status.enum";
import { Order } from "@/interfaces/orders.interface";
import { OrdersDetails, Product } from "@/interfaces/products.interface";

export function getOrdersDetails(orders: Order[], product: Product, globalIncome: number): OrdersDetails {
  let amount = 0;
  let sell = 0;
  let purchase = 0;
  let commission = 0;
  let refunds = 0;
  orders.forEach((order) => {
    amount += order.amount;
    sell += order.sellPrice * order.amount;
    purchase += order.purchasePrice * order.amount;
    commission += order.commission;
    refunds += order.status === OrderStatus.canceled ? 1 : 0;
  });

  const averageSell = amount ? sell / amount : 0;
  const averageCommission = amount ? commission / amount : 0;
  const averagePurchase = amount ? purchase / amount : 0;
  const profit = averageSell - averagePurchase - averageCommission;
  const income = (averageSell - averageCommission) * amount;
  const ros = averageSell ? profit / averageSell : 0;
  const refundsPercent = amount + refunds ? refunds / (amount + refunds) : 0;

  return {
    amount: amount,
    sellPrice: orders.length ? averageSell : product.sellPrice,
    purchasePrice: orders.length ? averagePurchase : product.purchasePrice,
    commission: averageCommission,
    income: income,
    profit: profit,
    profitAll: profit * amount,
    ros: ros * 100,
    share: globalIncome ? (income / globalIncome) * 100 : 0,
    shareSum: globalIncome ? (income / globalIncome) * 100 : 0,
    refunds: refunds,
    refundsPercent: refundsPercent * 100,
  };
}

export function getOrdersDetailsInit(): OrdersDetails {
  return {
    amount: 0,
    sellPrice: 0,
    purchasePrice: 0,
    commission: 0,
    income: 0,
    profit: 0,
    profitAll: 0,
    ros: 0,
    share: 0,
    shareSum: 0,
    refunds: 0,
    refundsPercent: 0,
  };
}
