
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "GeneralData",
  computed: {
    ...mapState({
      data: (state: unknown) => (state as State).kazanExpress.data,
      loading: (state: unknown) => (state as State).kazanExpress.loading,
    }),
    ...mapGetters({
      getGlobalDetails: "kazanExpress/getGlobalDetails",
    }),
  },
});
