
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { State } from "@/store";

export default defineComponent({
  name: "Home",
  computed: {
    ...mapState({
      kazanExpressData: (state: unknown) => (state as State).kazanExpress.data,
      paymentServerData: (state: unknown) => (state as State).paymentServer.data,
    }),
  },
});
