
import { Order } from "@/interfaces/orders.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "ChartsData",
  data() {
    return {
      chartData1: {},
      chartData2: {},
      chartData3: {},
      chartData4: {},
      chartOptions1: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
              callback: (label: string) => {
                return label + "₽";
              },
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
      chartOptions2: {
        stacked: false,
        plugins: {
          legend: {
            labels: {
              color: "#495057",
              render: "percentage",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            ticks: {
              color: "#495057",
              callback: (label: string) => {
                return label + "%";
              },
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
      chartOptions3: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
              callback: (label: string) => {
                return label + "шт.";
              },
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
      chartOptions4: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
              callback: (label: string) => {
                return label + "₽";
              },
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      data: (state: unknown) => (state as State).kazanExpress.data,
      loading: (state: unknown) => (state as State).kazanExpress.loading,
    }),

    ...mapGetters({
      isLoading: "kazanExpress/isLoading",
    }),
  },
  methods: {
    updateChartData(orders: Order[], isDay = true) {
      const labels: string[] = [];
      const listIncome: number[] = [];
      const listPurchase: number[] = [];
      const listProfit: number[] = [];
      const listAmount: number[] = [];
      const listOrders: number[] = [];
      const listAverageCheck: number[] = [];

      let bufMonth = -1;
      let bufYear = -1;
      let bufDay = -1;

      let bufIncome = 0;
      let bufPutchase = 0;
      let bufAmount = 0;
      let bufOrders = 0;

      orders
        .sort((a, b) => a.date - b.date)
        .forEach((order, idx) => {
          const date = new Date(order.date);
          if (bufDay !== -1 && bufMonth !== -1 && bufYear !== -1) {
            if (
              (date.getDate() !== bufDay && isDay) ||
              date.getMonth() !== bufMonth ||
              date.getFullYear() !== bufYear
            ) {
              pushData();
              bufIncome = 0;
              bufPutchase = 0;
              bufAmount = 0;
              bufOrders = 0;
            }
          }
          bufYear = date.getFullYear();
          bufMonth = date.getMonth();
          bufDay = date.getDate();

          bufIncome += order.sellPrice * order.amount;
          bufPutchase += order.purchasePrice * order.amount;
          bufAmount += order.amount;
          bufOrders += 1;

          if (idx === this.data.orders.length - 1) {
            pushData();
          }
        });

      function pushData(): void {
        // eslint-disable-next-line prettier/prettier
        labels.push(`${isDay ? (bufDay <= 9 ? "0" : "") + bufDay.toString() + "." : ""}${bufMonth < 9 ? "0" : ""}${bufMonth + 1}.${bufYear % 100}`);
        listIncome.push(bufIncome);
        listPurchase.push(bufPutchase);
        listProfit.push(bufIncome ? ((bufIncome - bufPutchase) / bufIncome) * 100 : 0);
        listAmount.push(bufAmount);
        listOrders.push(bufOrders);
        listAverageCheck.push(bufOrders ? bufIncome / bufOrders : 0);
      }

      this.chartData1 = {
        labels: labels,
        datasets: [
          {
            label: "Выручка",
            data: listIncome,
            borderColor: "#42A5F5",
            tension: 0.4,
            pointBackgroundColor: "#42A5F5",
          },
          {
            label: "Себестоимость",
            data: listPurchase,
            tension: 0.4,
            borderColor: "#FFBF00",
            pointBackgroundColor: "#FFBF00",
          },
        ],
      };

      this.chartData2 = {
        labels: labels,
        datasets: [
          {
            label: "Доходность",
            data: listProfit,
            fill: false,
            borderColor: "#FFA726",
            tension: 0.4,
            backgroundColor: "rgba(255,167,38,0.1)",
            pointBackgroundColor: "#FFA726",
          },
        ],
      };

      this.chartData3 = {
        labels: labels,
        datasets: [
          {
            label: "Продано товаров",
            data: listAmount,
            tension: 0.4,
            borderColor: "#FC7967",
            pointBackgroundColor: "#FC7967",
          },
          {
            label: "Количество заказов",
            data: listOrders,
            tension: 0.4,
            borderColor: "#85CA88",
            pointBackgroundColor: "#85CA88",
          },
        ],
      };

      this.chartData4 = {
        labels: labels,
        datasets: [
          {
            label: "Средний чек",
            data: listAverageCheck,
            tension: 0.4,
            borderColor: "#9A78D4",
            pointBackgroundColor: "#9A78D4",
          },
        ],
      };
    },
  },
  watch: {
    "data.orders": {
      handler: function (orders: Order[]) {
        if (!this.isLoading) {
          this.updateChartData(orders, this.data.isDay);
        }
      },
    },

    "data.isDay": {
      handler: function (isDay: boolean) {
        if (!this.isLoading) {
          this.updateChartData(this.data.orders, isDay);
        }
      },
    },
  },
  mounted() {
    if (!this.isLoading) {
      this.updateChartData(this.data.orders, this.data.isDay);
    }
  },
});
