import { Auth, Registr } from "@/interfaces/auth.interface";
import { PayRequest, PayResponse } from "@/interfaces/pay.interface";
import { Subscription, SubscriptionTariff } from "@/interfaces/subscription.interface";
import axios from "axios";
import { ActionContext } from "vuex";
import { State } from ".";

type Context = ActionContext<PaymentServerState, State>;

export interface PaymentServerState {
  data: {
    isAuth: boolean;
    accessToken: string;
    subscriptions: Subscription[];
    tariffs: SubscriptionTariff[];
    payResponse: PayResponse;
  };
  loading: {
    login: boolean;
    registration: boolean;
    subscriptions: boolean;
    tariffs: boolean;
    pay: boolean;
  };
}

export const paymentServerModule = {
  state: (): PaymentServerState => ({
    data: {
      isAuth: JSON.parse(localStorage.isAuthPayment || false),
      accessToken: localStorage.accessTokenPayment || "",
      subscriptions: Array<Subscription>(),
      tariffs: Array<SubscriptionTariff>(),
      payResponse: Object(),
    },
    loading: {
      login: false,
      registration: false,
      subscriptions: false,
      tariffs: false,
      pay: false,
    },
  }),

  actions: {
    async auth(context: Context, auth: Auth): Promise<void> {
      context.state.loading.login = true;

      return axios
        .post(`${process.env.VUE_APP_PAYMENT_SERVER}/login`, {
          email: auth.username,
          password: auth.password,
        })
        .then((res) => {
          context.state.data.accessToken = res.data.accessToken;
          context.state.data.isAuth = true;
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.login = false;
          localStorage.isAuthPayment = context.state.data.isAuth;
          localStorage.accessTokenPayment = context.state.data.accessToken;
        });
    },

    async registration(context: Context, auth: Registr): Promise<void> {
      context.state.loading.registration = true;

      return axios
        .post(`${process.env.VUE_APP_PAYMENT_SERVER}/register`, {
          email: auth.username,
          password: auth.password,
          name: auth.name,
        })
        .then((res) => {
          context.state.data.accessToken = res.data.accessToken;
          context.state.data.isAuth = true;
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.registration = false;
          localStorage.isAuthPayment = context.state.data.isAuth;
          localStorage.accessTokenPayment = context.state.data.accessToken;
        });
    },

    async getSubscriptions(context: Context): Promise<void> {
      context.state.loading.subscriptions = true;

      return axios
        .get(`${process.env.VUE_APP_PAYMENT_SERVER}/api/subscribes`, {
          headers: { Authorization: "Bearer " + context.state.data.accessToken },
        })
        .then((res) => {
          context.state.data.subscriptions = res.data;
        })
        .catch((err) => {
          context.state.data.subscriptions = [];
          if ([401, 422].includes(err.response.status)) {
            context.state.data.isAuth = false;
            localStorage.isAuthPayment = false;
          }
          throw err;
        })
        .finally(() => {
          context.state.loading.subscriptions = false;
        });
    },

    async getTariffs(context: Context): Promise<void> {
      context.state.loading.tariffs = true;

      return axios
        .get(`${process.env.VUE_APP_PAYMENT_SERVER}/api/tariffs`, {
          headers: { Authorization: "Bearer " + context.state.data.accessToken },
        })
        .then((res) => {
          context.state.data.tariffs = res.data;
        })
        .catch((err) => {
          if ([401, 422].includes(err.response.status)) {
            context.state.data.isAuth = false;
            localStorage.isAuthPayment = false;
          }
          throw err;
        })
        .finally(() => {
          context.state.loading.tariffs = false;
        });
    },

    async pay(context: Context, params: PayRequest): Promise<void> {
      context.state.loading.pay = true;

      return axios
        .post(
          `${process.env.VUE_APP_PAYMENT_SERVER}/api/subscribe`,
          { tariff_id: params.tariffId, return_url: params.returnUrl },
          { headers: { Authorization: "Bearer " + context.state.data.accessToken } }
        )
        .then((res) => {
          context.state.data.payResponse = res.data;
        })
        .catch((err) => {
          if ([401, 422].includes(err.response.status)) {
            context.state.data.isAuth = false;
            localStorage.isAuthPayment = false;
          }
          throw err;
        })
        .finally(() => {
          context.state.loading.pay = false;
        });
    },
  },

  getters: {
    isLoading(state: PaymentServerState): boolean {
      return Object.values(state.loading).includes(true);
    },
  },
  namespaced: true,
};
