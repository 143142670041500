<template>
  <router-view />
</template>

<style lang="scss">
#app {
  height: calc(100vh - 16px);
  display: flex;
}
</style>
