
import { defineComponent } from "vue";

export default defineComponent({
  name: "InfoCardComponent",
  props: {
    label: String,
    value: String,
  },
});
