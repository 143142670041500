import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import components from "@/components";
import VueAxios from "vue-axios";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Card from "primevue/card";
import Menubar from "primevue/menubar";
import SplitButton from "primevue/splitbutton";
import ProgressSpinner from "primevue/progressspinner";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Chart from "primevue/chart";
import Fieldset from "primevue/fieldset";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";

import "primeflex/primeflex.scss";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "@/assets/sass/style.scss";

const app = createApp(App);

components.forEach((component) => app.component(component.name, component));

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(PrimeVue);
app.use(ToastService);
app.component("Toast", Toast);
app.component("Fieldset", Fieldset);
app.component("Chart", Chart);
app.component("Checkbox", Checkbox);
app.component("Calendar", Calendar);
app.component("ProgressSpinner", ProgressSpinner);
app.component("SplitButton", SplitButton);
app.component("Menubar", Menubar);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("InputNumber", InputNumber);
app.component("Button", Button);
app.component("InputSwitch", InputSwitch);
app.component("Dropdown", Dropdown);
app.mount("#app");
