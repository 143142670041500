import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/HomeView.vue";
import MainTable from "@/views/MainTableView.vue";
import GeneralData from "@/views/GeneralDataView.vue";
import AnalyticsTable from "@/views/AnalyticsTableView.vue";
import ChartsData from "@/views/ChartsDataView.vue";
import { RouterName } from "@/enums/router-name.enum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: RouterName.generalData,
        component: GeneralData,
      },
      {
        path: "/selling",
        name: RouterName.mainTable,
        component: MainTable,
      },
      {
        path: "/analytics",
        name: RouterName.analyticsTable,
        component: AnalyticsTable,
      },
      {
        path: "/charts",
        name: RouterName.chartsData,
        component: ChartsData,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
