import Vuex from "vuex";
import { kazanExpressModule, KazanExpressState } from "./kazan-express";
import { PaymentServerState, paymentServerModule } from "./payment-server";

export interface State {
  kazanExpress: KazanExpressState;
  paymentServer: PaymentServerState;
}

export default new Vuex.Store<State>({
  modules: {
    kazanExpress: kazanExpressModule,
    paymentServer: paymentServerModule,
  },
});
