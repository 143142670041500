import { OrderStatus } from "@/enums/order-status.enum";
import { Auth } from "@/interfaces/auth.interface";
import { GlobalDetails } from "@/interfaces/global-details.interface";
import { Order } from "@/interfaces/orders.interface";
import { Product } from "@/interfaces/products.interface";
import { Shop } from "@/interfaces/shop.interface";
import { TopItem } from "@/interfaces/top-item";
import axios from "axios";
import { ActionContext } from "vuex";
import { State } from ".";
import { getOrdersDetails, getOrdersDetailsInit } from "./utils/orders.utils";

type Context = ActionContext<KazanExpressState, State>;

export interface KazanExpressState {
  data: {
    isAuth: boolean;
    accessToken: string;
    orders: Order[];
    ordersAll: Order[];
    products: Product[];
    productsAll: Product[];
    shops: Shop[];
    minDate: Date | undefined;
    maxDate: Date | undefined;
    date: Date[];
    tax: number;
    activeOrders: number;
    isDay: boolean;
    lenTop: number;
    selectedShop: number;
    totalAmtOrders: number;
    isTryEnter: boolean;
    searchQuery: string;
  };
  loading: {
    login: boolean;
    orders: boolean;
    products: boolean;
    shops: boolean;
  };
  maxRequestSize: number;
}

export const kazanExpressModule = {
  state: (): KazanExpressState => ({
    data: {
      isAuth: JSON.parse(localStorage.isAuthKazanExpress || false),
      accessToken: localStorage.accessTokenKazanExpress || "",
      orders: Array<Order>(),
      ordersAll: Array<Order>(),
      products: Array<Product>(),
      productsAll: Array<Product>(),
      shops: Array<Shop>(),
      minDate: undefined,
      maxDate: undefined,
      date: Array<Date>(),
      tax: 6,
      activeOrders: 0,
      isDay: true,
      lenTop: 10,
      selectedShop: 0,
      totalAmtOrders: 0,
      isTryEnter: false,
      searchQuery: "",
    },
    loading: {
      login: false,
      orders: false,
      products: false,
      shops: false,
    },
    maxRequestSize: 10000,
  }),
  mutations: {
    filterData(state: KazanExpressState): void {
      state.data.orders = state.data.ordersAll.filter(
        (order) => !state.data.selectedShop || order.shopId === state.data.selectedShop
      );
      state.data.products = state.data.productsAll.filter(
        (product) => !state.data.selectedShop || product.shopId === state.data.selectedShop
      );

      const startDate = state.data.date[0];
      const endDate = state.data.date[1];

      if (startDate && endDate) {
        state.data.orders = state.data.orders.filter(
          (order) => order.date >= startDate.getTime() && order.date <= endDate.getTime() + 3600000 * 24
        );

        let globalIncome = 0;
        state.data.orders.forEach((order) => (globalIncome += order.sellPrice * order.amount - order.commission));

        state.data.products.forEach((product) => {
          const orders = product.ordersList.filter(
            (order) => order.date >= startDate.getTime() && order.date <= endDate.getTime() + 3600000 * 24
          );
          product.ordersDetails = getOrdersDetails(orders, product, globalIncome);
        });
        state.data.products = state.data.products.sort((a, b) => b.ordersDetails.shareSum - a.ordersDetails.shareSum);
        for (let idx = 1; idx < state.data.products.length; idx++) {
          state.data.products[idx].ordersDetails.shareSum += state.data.products[idx - 1].ordersDetails.shareSum;
        }
      }
    },
  },
  actions: {
    async auth(context: Context, auth: Auth): Promise<void> {
      context.state.loading.login = true;

      const formData = new FormData();
      formData.append("grant_type", "password");
      formData.append("username", auth.username);
      formData.append("password", auth.password);

      return axios({
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${process.env.VUE_APP_KE_SERVER}/api/oauth/token`,
        data: formData,
        auth: {
          username: "kazanexpress",
          password: "secretKey",
        },
        withCredentials: true,
      })
        .then((res) => {
          context.state.loading.login = false;
          context.state.data.accessToken = res.data.access_token;
          context.state.data.isAuth = true;
          localStorage.isAuthKazanExpress = context.state.data.isAuth;
          localStorage.accessTokenKazanExpress = context.state.data.accessToken;
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.login = false;
        });
    },

    async fetchShops(context: Context): Promise<void> {
      context.state.loading.shops = true;
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_KE_SERVER}/api/seller/shop/`,
        headers: { Authorization: "Bearer " + context.state.data.accessToken },
        withCredentials: true,
      })
        .then((res) => {
          context.state.data.shops = res.data;
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.shops = false;
        });
    },

    async fetchTotalAmtOrders(context: Context): Promise<void> {
      context.state.loading.orders = true;

      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_KE_SERVER}/api/seller/finance/orders`,
        headers: { Authorization: "Bearer " + context.state.data.accessToken },
        params: { size: 1, page: 0, group: false },
        withCredentials: true,
      })
        .then((res) => {
          context.state.data.totalAmtOrders = res.data.totalElements;
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.orders = false;
        });
    },

    async fetchOrders(context: Context, page: number): Promise<void> {
      context.state.loading.orders = true;

      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_KE_SERVER}/api/seller/finance/orders`,
        headers: { Authorization: "Bearer " + context.state.data.accessToken },
        params: { size: context.state.maxRequestSize, page, group: false },
        withCredentials: true,
      })
        .then((res) => {
          context.state.data.orders = context.state.data.orders.concat(res.data.orderItems);
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.orders = false;
        });
    },

    async fetchProducts(context: Context, shopId: number): Promise<void> {
      context.state.loading.products = true;
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_KE_SERVER}/api/seller/shop/${shopId}/product/leftover-stock`,
        headers: { Authorization: "Bearer " + context.state.data.accessToken },
        withCredentials: true,
      })
        .then((res) => {
          res.data.products.forEach((product: Product) => {
            product.shopId = shopId;
            product.ordersList = [];
            product.ordersDetails = getOrdersDetailsInit();
          });
          context.state.data.products = context.state.data.products.concat(res.data.products);
        })
        .catch((err) => {
          context.state.data.isAuth = false;
          throw err;
        })
        .finally(() => {
          context.state.loading.products = false;
        });
    },
  },

  getters: {
    getGlobalDetails(state: KazanExpressState): GlobalDetails {
      const orders = state.data.orders.length;
      const sku = state.data.products.length;
      let purchase = 0;
      let purchaseAll = 0;
      let sellPrice = 0;
      let commission = 0;
      let withdrawn = 0;
      let amount = 0;
      let ros = 0;
      state.data.orders.forEach((order) => {
        purchase += order.purchasePrice * order.amount;
        sellPrice += order.sellPrice * order.amount;
        commission += order.commission;
        withdrawn += order.withdrawnProfit;
        amount += order.amount;
      });

      state.data.products.forEach((product) => {
        ros += product.ordersDetails.ros;
        purchaseAll += product.ordersDetails.purchasePrice;
      });

      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const bYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
      const yesterdayOrders = state.data.ordersAll.filter(
        (order) => yesterday.getTime() < order.date && order.date < today.getTime()
      );
      const bYesterdayOrders = state.data.ordersAll.filter(
        (order) => bYesterday.getTime() < order.date && order.date < yesterday.getTime()
      );

      const topDict: { [key: string]: TopItem } = {};
      yesterdayOrders.forEach(
        (order) =>
          (topDict[order.skuTitle] = { amount: 0, profit: 0, bAmount: 0, bProfit: 0, title: order.productTitle })
      );
      yesterdayOrders.forEach((order) => {
        topDict[order.skuTitle].amount += order.amount;
        topDict[order.skuTitle].profit += (order.sellPrice - order.purchasePrice) * order.amount - order.commission;
      });

      bYesterdayOrders.forEach((order) => {
        if (order.skuTitle in topDict) {
          topDict[order.skuTitle].bAmount += order.amount;
          topDict[order.skuTitle].bProfit += (order.sellPrice - order.purchasePrice) * order.amount - order.commission;
        }
      });

      const topAmount = Object.values(topDict)
        .sort((a, b) => b.amount - a.amount)
        .slice(0, state.data.lenTop);

      const topProfit = Object.values(topDict)
        .sort((a, b) => b.profit - a.profit)
        .slice(0, state.data.lenTop);

      return {
        finance: {
          income: sellPrice - commission,
          commission: commission,
          profit: sellPrice - commission - purchase,
          purchase: purchase,
          withdrawn: withdrawn,
          cleanProfit: (sellPrice - commission) * ((100 - state.data.tax) / 100) - purchase,
          tax: (sellPrice - commission) * (state.data.tax / 100),
        },
        orders: {
          amount: amount,
          orders: orders,
          processing: state.data.orders.filter((order) => order.status === OrderStatus.processing).length,
          canceled: state.data.orders.filter((order) => order.status === OrderStatus.canceled).length,
          withdraw: state.data.orders.filter((order) => order.status === OrderStatus.withdraw).length,
          averageAmount: orders ? amount / orders : 0,
          averageCheck: orders ? sellPrice / orders : 0,
        },
        average: {
          buySku: sku ? amount / sku : 0,
          purchase: sku ? purchaseAll / sku : 0,
          profit: amount ? (sellPrice - commission - purchase) / amount : 0,
          ros: sku ? ros / sku : 0,
          incomeSku: sku ? (sellPrice - commission) / sku : 0,
          profitSku: sku ? (sellPrice - commission - purchase) / sku : 0,
        },
        top: {
          date: yesterday.toLocaleDateString(),
          amount: topAmount,
          profit: topProfit,
        },
      };
    },

    isLoading(state: KazanExpressState): boolean {
      return Object.values(state.loading).includes(true);
    },
  },
  namespaced: true,
};
