
import * as XLSX from "xlsx";
import { RouterName } from "@/enums/router-name.enum";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "FooterComponent",
  data() {
    return {
      routerName: RouterName,
    };
  },
  computed: {
    ...mapState({
      data: (state: unknown) => (state as State).kazanExpress.data,
    }),
  },
  methods: {
    ...mapMutations({
      filterData: "kazanExpress/filterData",
    }),

    showAll(): void {
      this.data.date = [this.data.minDate, this.data.maxDate] as Date[];
      this.filterData();
    },

    exportTable(): void {
      const products = this.data.productsAll.filter((product) => product.quantityActive <= this.data.activeOrders);
      const rows = [] as unknown[];
      rows.push({
        name: "Наименование",
        sku: "SKU",
        quantityActive: "Остаток (шт)",
        amount: "Продано (шт)",
        refunds: "Возвраты (шт)",
        sellPrice: "Цена за шт. (руб)",
        purchasePrice: "Себестоимость за шт. (руб)",
        commission: "Комиссия за шт. (руб)",
        profit: "Прибыль за шт. (руб)",
      });
      products
        .sort((a, b) => a.quantityActive - b.quantityActive)
        .forEach((row) => {
          rows.push({
            name: row.name,
            sku: row.sku,
            quantityActive: +row.quantityActive,
            amount: +row.ordersDetails.amount,
            refunds: +row.ordersDetails.refunds,
            sellPrice: +row.ordersDetails.sellPrice.toFixed(2),
            purchasePrice: +row.ordersDetails.purchasePrice,
            commission: +row.ordersDetails.commission.toFixed(2),
            profit: +row.ordersDetails.profit.toFixed(2),
          });
        });

      const sn = `Отчет ${this.data.minDate?.toLocaleDateString()}-${this.data.maxDate?.toLocaleDateString()}`;
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(rows, { skipHeader: true });
      ws["!cols"] = [{ wpx: 120 }, { wpx: 160 }];

      XLSX.utils.book_append_sheet(wb, ws, sn);
      return XLSX.writeFile(wb, "analytics.xlsx");
    },
  },
});
