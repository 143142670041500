
import { Auth } from "@/interfaces/auth.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { SubscriptionStatus } from "@/enums/subscription.enum";

export default defineComponent({
  name: "DashboardComponent",
  data() {
    return {
      authData: {
        username: localStorage.loginKazanExpress || "",
        password: localStorage.passwordKazanExpress || "",
      } as Auth,
      isSubmited: false,
      reg: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    ...mapState({
      kazanExpressData: (state: unknown) => (state as State).kazanExpress.data,
      paymentServerData: (state: unknown) => (state as State).paymentServer.data,
      kazanExpressLoading: (state: unknown) => (state as State).kazanExpress.loading,
      paymentServerLoading: (state: unknown) => (state as State).paymentServer.loading,
    }),
  },
  methods: {
    ...mapActions({
      getSubscriptions: "paymentServer/getSubscriptions",
      getTariffs: "paymentServer/getTariffs",
      authKazanExpress: "kazanExpress/auth",
    }),

    loginKazanExpress(): void {
      this.isSubmited = true;
      this.authKazanExpress(this.authData)
        .then(() => {
          localStorage.loginKazanExpress = this.authData.username;
          localStorage.passwordKazanExpress = this.authData.password;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ошибка входа",
            detail: "Неверные данные номера телефона/email или пароля",
            life: 3000,
          });
        });
    },

    isPayment(): boolean {
      return !!this.paymentServerData.subscriptions.filter((subscription) => {
        if (subscription.status === SubscriptionStatus.Succeeded) {
          const expTariff =
            this.paymentServerData.tariffs.find((tariff) => tariff.id === subscription.tariff_id)?.exp || 0;
          const date = new Date(Date.parse(subscription.subscribe) + expTariff * (1000 * 60 * 60 * 24));
          const now = new Date();
          if (date > now) {
            return true;
          }
        }
        return false;
      }).length;
    },

    isSubmitDisabled(): boolean {
      return !this.isEmailValid() || !this.isPasswordValid();
    },

    isEmailValid(): boolean {
      return this.reg.test(this.authData.username);
    },

    isPasswordValid(): boolean {
      return this.authData.password != "";
    },
  },
  mounted() {
    this.getSubscriptions();
    this.getTariffs();
  },
});
