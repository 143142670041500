
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "DashboardHeaderComponent",
  computed: {
    ...mapState({
      paymentServerData: (state: unknown) => (state as State).paymentServer.data,
    }),
  },
  methods: {
    logout(): void {
      localStorage.accessTokenPayment = "";
      localStorage.isAuthPayment = false;
      this.paymentServerData.isAuth = false;
    },
  },
});
