
import { RouterName } from "@/enums/router-name.enum";
import { SelectLabels } from "@/interfaces/select-labels.interface";
import { State } from "@/store";
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

interface ShopOptions {
  id: number;
  name: string;
}

const labels: SelectLabels = {
  general: "Общие показатели",
  selling: "Данные по продажам",
  analytics: "Аналитика",
  charts: "Графики",
};

export default defineComponent({
  name: "HeaderComponent",
  data() {
    return {
      routerName: RouterName,
      showAll: true,
      items: [
        { label: labels.general, icon: "pi pi-chart-bar", to: "/" },
        {
          label: labels.selling,
          icon: "pi pi-table",
          to: "/selling",
        },
        {
          label: labels.analytics,
          icon: "pi pi-chart-line",
          to: "/analytics",
        },
        {
          label: labels.charts,
          icon: "pi pi-chart-pie",
          to: "/charts",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      kazanExpressData: (state: unknown) => (state as State).kazanExpress.data,
      paymentServerData: (state: unknown) => (state as State).paymentServer.data,
      kazanExpressLoading: (state: unknown) => (state as State).kazanExpress.loading,
      paymentServerLoading: (state: unknown) => (state as State).paymentServer.loading,
    }),

    selectLabel(): string[] {
      this.clearSearchQuery();
      switch (this.$route.name) {
        case RouterName.generalData:
          return [labels.general, "pi pi-chart-bar"];
        case RouterName.mainTable:
          return [labels.selling, "pi pi-table"];
        case RouterName.analyticsTable:
          return [labels.analytics, "pi pi-chart-line"];
        case RouterName.chartsData:
          return [labels.charts, "pi pi-chart-pie"];
        default:
          return ["Выбор данных", "pi pi-cog"];
      }
    },

    shopOptions(): ShopOptions[] {
      const options = [{ name: "Все магазины", id: 0 }];
      return options.concat(this.kazanExpressData.shops.map((shop) => ({ name: shop.shopTitle, id: shop.id })));
    },
  },
  methods: {
    ...mapMutations({
      filterData: "kazanExpress/filterData",
    }),

    loguotKazanExpress(): void {
      localStorage.passwordKazanExpress = "";
      localStorage.isAuthKazanExpress = false;
      this.kazanExpressData.isAuth = false;
      this.$router.replace("/");
    },
    logoutSystem(): void {
      localStorage.accessTokenPayment = "";
      localStorage.isAuthPayment = false;
      this.paymentServerData.isAuth = false;
      this.$router.replace("/");
    },

    clearSearchQuery(): void {
      this.kazanExpressData.searchQuery = "";
    },
  },
});
