import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_login_component = _resolveComponent("login-component")!
  const _component_dashboard_component = _resolveComponent("dashboard-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, { position: "bottom-right" }),
    (!_ctx.paymentServerData.isAuth)
      ? (_openBlock(), _createBlock(_component_login_component, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.paymentServerData.isAuth)
      ? (_openBlock(), _createBlock(_component_dashboard_component, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}